import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import { useState } from 'react';
import Button from './Button';
import { toast } from 'react-toastify';
import axios from 'axios';

function SearchForPackageSection() {
  const [packageId, setPackageId] = useState<string>('');
  const [packageInfo, setPackageInfo] = useState<{
    success: boolean;
    statusHistoryList: {
      parcelNumber: string;
      code: string;
      description: string;
      creationDate: string;
    }[];
  } | null>(null);

  async function onSubmit() {
    const response = await toast.promise(
      axios
        .post(
          'https://api.nklogistic.pl/noweKoloryParcelApi/api/v1/getParcelHistory/',
          {
            parcelNumber: packageId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(
          (data) =>
            data.data as {
              success: boolean;
              statusHistoryList: {
                parcelNumber: string;
                code: string;
                description: string;
                creationDate: string;
              }[];
            },
        ),
      {
        pending: 'Szukanie paczki...',
        error: 'Wystąpił błąd przy szukaniu',
      },
    );

    setPackageInfo(response);
  }

  return (
    <div
      className="w-full flex flex-col justify-center items-center pb-12 pt-24 min-h-[70vh]"
      style={createStyleForBackgroundImage('/package-bg.webp')}
    >
      <div
        className={`flex flex-col justify-center items-center text-center backdrop-card gap-8 py-8 px-8 w-[90%] xl:w-1/2`}
      >
        {packageInfo && packageInfo.success ? (
          <>
            <div className="font-bold text-[35px] lg:text-[50px] text-center text-default">
              Paczka nr: {packageId}
            </div>
            <div className="text-default flex flex-col gap-2">
              {packageInfo.statusHistoryList.map((item) => (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-2 border border-solid border-primary py-2 px-4">
                  <div className="flex flex-col gap-2">
                    <div className="font-extrabold">Status:</div> {item.code}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="font-extrabold">Opis:</div>
                    {item.description}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="font-extrabold">Data:</div>
                    {item.creationDate}
                  </div>
                </div>
              ))}
            </div>
            <Button
              label="Sprawdź inną paczkę"
              onClick={() => setPackageInfo(null)}
            />
          </>
        ) : packageInfo && !packageInfo.success ? (
          <>
            <div className="font-bold text-[35px] lg:text-[50px] text-default">
              Wystąpił błąd
            </div>
            <div className="text-default">
              Nie znaleziono paczki o numerze: {packageId}
            </div>
            <Button
              label="Sprawdź inną paczkę"
              onClick={() => setPackageInfo(null)}
            />
          </>
        ) : (
          <>
            <div className="flex flex-col lg:flex-row gap-4 justify-center items-center w-full">
              <input
                type="text"
                className="h-[50px] w-[40%] outline-none border border-solid border-white text-white placeholder-white pl-8 bg-transparent"
                value={packageId}
                placeholder="Wpisz numer paczki"
                onChange={(e) => setPackageId(e.currentTarget.value)}
              />
              <Button label="Śledź paczkę" onClick={onSubmit} />
            </div>
            <div className="font-bold text-[35px] lg:text-[50px] text-default">
              Z nami śledzenie paczki jest tak proste, jak nigdy dotąd
            </div>
            <div className="text-default">
              masz pewność, gdzie i kiedy dotrze Twoja przesyłka, bez zbędnych
              zmartwień.
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SearchForPackageSection;
